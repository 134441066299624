import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const Volunteer = () => {
  return (
    <>
      <Typography
        variant="h5"
        component="div"
        style={{marginTop: "15px", marginBottom: "10px"}}
      >
        DO YOU WANNA VOLUNTEER?
      </Typography>
      <Typography variant="body2">
        Punk Illegal is now looking for people who want to support a good cause and at the same time have a wonderful
        time and music experience in the woods of Småland county, Sweden. We hope that's you! If you are interested in
        volunteering this summer for Punk Illegal, fill in the following form. The people who will get a volunteering
        spot, will work 2 shifts of 4 hours each. The shifts start Friday morning/noon and end Sunday afternoon. You
        need to bring a tent since there is no other accommodation close by. You will be offered food before or after
        your shift. Looking forward to your application!
      </Typography>
      <Typography variant="body2">// Punk Illegal crew</Typography>
      <Typography
        variant="h5"
        style={{marginTop: "15px"}}
      >
        <Box
          component="span"
          fontWeight="bold"
        >
          <a
            href={
              "https://docs.google.com/forms/d/e/1FAIpQLSelYrOkAYLd7XBXHQMI2O8XfnWC6ty4PaPsH2-wKCtB17b-SQ/viewform?usp=sf_link"
            }
            target="_blank"
            rel="noreferrer"
          >
            APPLY HERE
          </a>{" "}
        </Box>
      </Typography>
    </>
  );
};

export default Volunteer;
