import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const Distros = () => {
    return (
        <>
            <Typography
                variant="h5"
                component="div"
                style={{ marginTop: "15px", marginBottom: "10px" }}
            >
                DISTROS
            </Typography>
            <Typography variant="body2">
                Vi tar nu emot intresseanmälningar för er med distros som vill ha ett stånd på festivalen.
                Du betalar ditt eget inträde. Vi ser också gärna att du skänker 15 procent av din vinst till Ingen människa är illegal via oss.
                Vi ber dig ombesörja med eget bord. Om de reser med flyg och har svårt att ta med bord skriv det i denna anmälan.
            </Typography>
            <Typography variant="body2" style={{ marginTop: "15px" }}>We are now accepting registrations of interest from those with distros who want a booth at the festival.
                You will cover your own ticket admission. We also appreciate if you donate 15 percent of your profit to No One Is Illegal through us.
                We ask you to arrange your own table. If you are traveling by plane and have difficulty bringing a table, please let us know in this form.</Typography>
            <Typography
                variant="h5"
                style={{ marginTop: "15px" }}
            >
                <Box
                    component="span"
                    fontWeight="bold"
                >
                    <a
                        href={
                            "https://docs.google.com/forms/d/e/1FAIpQLSewyU2ADSIwyyOBZhKlL1jyI1CfalxuDGGODy5q5hpn3mhezg/viewform"
                        }
                        target="_blank"
                        rel="noreferrer"
                    >
                        ANMÄLAN / FORM
                    </a>{" "}
                </Box>
            </Typography>
        </>
    );
};

export default Distros;
