import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Image } from "mui-image";
import PosterSmall from "../assets/poster_small.jpg";



function Home() {


  return (
    <Container
      spacing={22}
      direction="column"
    >

      <Box

      >
        <Image style={{ borderRadius: "1%" }} src={PosterSmall} />
      </Box>

    </Container>
  );
}

export default Home;
