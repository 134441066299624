import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const Tickets = (props) => {
  return (
    <>
      <Typography
        variant="h5"
        component="div"
        style={{marginTop: "15px", marginBottom: "10px"}}
      >
        BILJETTER
      </Typography>
      <Typography variant="body2">
        Biljetter säljs bara här:{" "}
        <Box
          component="span"
          fontWeight="bold"
        >
          <a
            href={"https://www.biljettkiosken.se/punkillegal"}
            target="_blank"
          >
            biljettkiosken.se/punkillegal
          </a>{" "}
        </Box>
      </Typography>
      <Typography
        variant="body2"
        style={{marginTop: "10px"}}
      >
        Biljetten kostar 450 kronor och gäller för båda dagar. Inga endagarsbiljetter kommer att säljas. Viktigt att
        notera att Biljettkiosken tar ut en serviceavgift utöver detta pris. Priset inkluderar även camping.{" "}
        <Box
          component="span"
          fontWeight="bold"
        >
          OBS: Inga biljetter kommer att säljas på plats!
        </Box>
      </Typography>

      <Typography
        variant="h5"
        component="div"
        style={{marginTop: "15px", marginBottom: "10px"}}
      >
        TICKETS
      </Typography>
      <Typography variant="body2">
        Tickets are only sold through:{" "}
        <Box
          component="span"
          fontWeight="bold"
        >
          <a
            href={"https://www.biljettkiosken.se/punkillegal"}
            target="_blank"
            rel="noreferrer"
          >
            biljettkiosken.se/punkillegal
          </a>{" "}
        </Box>
      </Typography>
      <Typography
        variant="body2"
        style={{marginTop: "10px"}}
      >
        The ticket costs SEK 450 and is valid both days.There will be no one day tickets. It is important to note that
        Biljettkiosken charges a service fee in addition to this price. The price also includes camping.{" "}
        <Box
          component="span"
          fontWeight="bold"
        >
          Note! No tickets will be sold on site!
        </Box>
      </Typography>
    </>
  );
};

export default Tickets;
