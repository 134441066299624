import Typography from "@mui/material/Typography";
import { List, ListItem } from "@mui/material";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";

const Info = (props) => {
  return (
    <>
      <Typography
        variant="h5"
        component="div"
        style={{ marginTop: "15px", marginBottom: "10px" }}
      >
        INFO
      </Typography>
      <Typography
        variant="body2"
        style={{ marginTop: "10px", marginBottom: "10px" }}
      >
        {" "}
        <Box
          component="span"
          fontWeight="bold"
        >
          <Link to="/info-en">INFORMATION IN ENGLISH HERE</Link>
        </Box>
      </Typography>

      <Typography variant="body2">
        Välkommen till Svartmyra utanför Värnamo den 12:e & 13:e juli 2024! Campingområdet kommer att vara öppet mellan
        fredag 12/7 klockan 12 och söndag 14/7 klockan 15. Då finns Punk Illegal-folk på plats och hjälper till med
        frågor och information. Festivalen drar igång fredagen 12/7 på Svartmyra. Inträdet där biljetter byts mot
        armband öppnas 12 på fredagen. Absolut inget våld, ingen rasism, ingen sexism, ingen homofobi! Ta hand om
        varandra!
      </Typography>
      <Typography
        variant="h5"
        component="div"
        style={{ marginTop: "15px", marginBottom: "10px" }}
      >
        BILJETTER
      </Typography>
      <Typography variant="body2">
        Biljetter säljs bara här:{" "}
        <Box
          component="span"
          fontWeight="bold"
        >
          <a
            href={"https://www.biljettkiosken.se/punkillegal"}
            target="_blank"
            rel="noreferrer"
          >
            biljettkiosken.se/punkillegal
          </a>{" "}
        </Box>
      </Typography>
      <Typography variant="body2">
        Biljetten kostar 450 kronor och gäller för båda dagar. Inga endagarsbiljetter kommer att säljas. Viktigt att
        notera att Biljettkiosken tar ut en serviceavgift utöver detta pris. Priset inkluderar även camping. OBS: Inga
        biljetter kommer att säljas på plats!
      </Typography>
      <Typography
        variant="h5"
        component="div"
        style={{ marginTop: "15px", marginBottom: "10px" }}
      >
        MAT & DRYCK
      </Typography>
      <Typography variant="body2">
        Under festivalen kommer Punk Illegal att servera en hel del god mat och dryck. Du kan välja mellan olika
        veganska alternativ. Fråga vid allergi. Det kommer också att finnas en food truck som serverar vegansk mat. Även
        frukost kommer att säljas. Det kommer finnas gott om dryck till försäljning.
      </Typography>
      <Typography
        variant="h5"
        component="div"
        style={{ marginTop: "15px", marginBottom: "10px" }}
      >
        SOPHANTERING
      </Typography>
      <Typography variant="body2">
        Sopstationer finns utsatta på strategiska ställen och ska naturligtvis användas flitigt för sortering av skräp
        och skrot. En självstädande fest är vad vi eftersträvar och vad vi alla vill ha. Slarva inte med att slänga
        fimpar och småskräp på marken. Vi har upptäckt att fåglarna bygger bo av era nikotinrester.
      </Typography>
      <Typography
        variant="h5"
        component="div"
        style={{ marginTop: "15px", marginBottom: "10px" }}
      >
        SPRAYBURKAR/TAGPENNOR:
      </Typography>
      <Typography variant="body2">Vi uppskattar att alla tuschpennor, sprayburkar och annat lämnas hemma.</Typography>
      <Typography
        variant="h5"
        component="div"
        style={{ marginTop: "15px", marginBottom: "10px" }}
      >
        CAMPINGEN
      </Typography>
      <Typography variant="body2">
        Då vi inte har plats att husera alla som kommer är det camping som gäller. Denna sker på utsatt område och vi
        ber alla att hålla sig inom detta. Ni kan tälta, bo i husvagn/husbil, eller sova under bar himmel. Enkla
        toaletter & rinnande vatten kommer finnas på plats. I närheten finns även badplatser, dock bilavstånd om du inte
        är hurtig. På campingen är det viktigt att alla respekterar varandra. Med tanke på omgivning får under inga
        omständigheter eldar tändas. Respektera detta då vi befinner oss ute på landet med ängar och lador. Detta gäller
        även utanför festivalens område. Campingkök/Triangakök är tillåtna men var försiktig när du använder dem! Använd
        dassen och pissrännan då boende på gården även ska kunna bo där efter festens slut. Håll dem rena så gott det
        går. Kasta skräp i återvinning eller papperskorgarna och städa runt din tältplats innan du åker hem! Respektera
        dina campinggrannar och var snäll! Det är också folk som bor på området året runt, så tänk på att de vill ha det
        snyggt även efter festivalen! Parkering sker på utsatt plats inom området. Följ parkering- och campingansvarigas
        direktiv. Det är förbjudet att bo på campingen utan biljett.
      </Typography>
      <Typography
        variant="h5"
        component="div"
        style={{ marginTop: "15px", marginBottom: "10px" }}
      >
        ANDRA PLATSER FÖR ÖVERNATTNING
      </Typography>
      <Typography variant="body2">
        Vi rekommenderar att ni campar för det är bara bilavstånd till campingstugor och hotell och kollektivtrafiken
        stannar inte nära. Vill ni ha tips om boende ända finns exempelvis Värnamo camping:
        <Box
          component="span"
          fontWeight="bold"
        >
          <a
            href={"https://camping.se/sv/campingar/2677/Varnamo-Camping-Prostsjon"}
            target="_blank"
            rel="noreferrer"
          >
            camping.se/sv/campingar/2677/Varnamo-Camping-Prostsjon
          </a>{" "}
        </Box>
      </Typography>
      <Typography
        variant="h5"
        component="div"
        style={{ marginTop: "15px", marginBottom: "10px" }}
      >
        BETALNING PÅ FESTIVALEN
      </Typography>
      <Typography variant="body2">
        Swish och kontanter är enda betalningsmedel. Närmaste bankomat ligger långt bort så se till att ta ut kontanter
        innan du åker.
      </Typography>
      <Typography
        variant="h5"
        component="div"
        style={{ marginTop: "15px", marginBottom: "10px" }}
      >
        MERCH/SKIVOR
      </Typography>
      <Typography variant="body2">
        De flesta band tar med sin egen merch och säljer den i den officiella merchshoppen inomhus vid baren. Det kommer
        även finnas andra distros på festivalområdet.
      </Typography>
      <Typography
        variant="h5"
        component="div"
        style={{ marginTop: "15px", marginBottom: "10px" }}
      >
        HUNDAR OCH BARN
      </Typography>
      <Typography variant="body2">
        Hundar får tas med men måste alltid hållas kopplade då det finns andra djur på gården som inte uppskattar
        jagande hundar. Döda djur, i vilket skick de än är eller hur de än är förpackade, lämnas naturligtvis hemma. Vi
        ser ogärna hundar framför scen på grund av hög musik. Barn upp till 13 kommer bara in med målsman (gratis), men
        se till att de har hörselskydd! Ungdomar under 18 kommer att få ett speciellt festivalarmband.
      </Typography>
      <Typography
        variant="h5"
        component="div"
        style={{ marginTop: "15px", marginBottom: "10px" }}
      >
        BIL
      </Typography>
      <Typography variant="body2">
        Det är allra lättast att ta sig till Svartmyra med bil. Försök att samåka. Vi kommer också att starta en tråd på
        Facebook om samåkning.
      </Typography>
      <Typography
        variant="h5"
        component="div"
        style={{ marginTop: "15px", marginBottom: "10px" }}
      >
        FLYG
      </Typography>
      <Typography variant="body2">
        De närmaste flygplatserna ligger i Växjö och Jönköping men de flyger till få destinationer. Annars ligger
        Köpenhamn och Göteborg flygplatser cirka 3 timmar bort med buss/tåg.
      </Typography>
      <Typography
        variant="h5"
        component="div"
        style={{ marginTop: "15px", marginBottom: "10px" }}
      >
        TÅG
      </Typography>
      <Typography variant="body2">Den närmaste tågstationen ligger i Värnamo.</Typography>
      <Typography
        variant="h5"
        component="div"
        style={{ marginTop: "15px", marginBottom: "10px" }}
      >
        SHUTTLEBUS
      </Typography>
      <Typography variant="body2">Om du reser med tåg och behöver hämtas från Värnamo tågstation behöver vi veta så att vi kan hämta dig. Festivalen ligger cirka 30 kilometer utanför Värnamo.

        Lite information information om vår shuttle bus:

        <List
          sx={{ listStyleType: "disc", pl: 4 }}
          dense
        >
          <ListItem sx={{ display: "list-item" }}>
            {" "}
            Bussen går endast fredag ​​12 och söndag 14 juli. På fredag ​​hämtar vi upp mellan 12.00 och 20.00. På söndag kommer vi att köra mellan klockan 10 och 14.
          </ListItem>

          <ListItem sx={{ display: "list-item" }}>
            Bussresan kostar 50 kronor (Swish eller kontant) eller 5 Euro (kontant) enkel resa.

          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Bussen är en 9-sitsig minibuss, och den kommer att stanna utanför tågstationen i Värnamo.
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Bussen går när den är full, och det innebär att du kan behöva vänta ett tag i Värnamo, eller att du kan behöva åka tillbaka någon timme tidigare till Värnamo än ditt tåg går.
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Bussen tar 8 passagerare vilket innebär att du kan behöva vänta om bussen är full tills bussen kommer tillbaka igen.
          </ListItem>
        </List>

        <Typography
          variant="h6"

        >
          <Box
            component="span"
            fontWeight="bold"
          >
            <a
              href={
                "https://docs.google.com/forms/d/1ln2vlivgNJq_gLxNTEswJQ8wPX6SYQUmwGovS7yK8oQ/edit?chromeless=1"
              }
              target="_blank"
              rel="noreferrer"
            >
              ANMÄL DIG TILL BUSSEN HÄR
            </a>{" "}
          </Box>
        </Typography>
      </Typography>
      <Typography
        variant="h5"
        component="div"
        style={{ marginTop: "15px", marginBottom: "10px" }}
      >
        TILLGÄNGLIGHET
      </Typography>
      <Typography variant="body2">
        <List
          sx={{ listStyleType: "disc", pl: 4 }}
          dense
        >
          <ListItem sx={{ display: "list-item" }}>
            <Box
              component="span"
              fontWeight="bold"
            >
              Ljud/ljus:
            </Box>{" "}
            Hörslingor finns ej på scen.
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            {" "}
            <Box
              component="span"
              fontWeight="bold"
            >
              Ledsagare:
            </Box>{" "}
            Ledsagare och vårdare går in kostnadsfritt vid uppvisande av ledsagarpass. Ledsagarhundar likaså men ej
            under konsert.
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            {" "}
            <Box
              component="span"
              fontWeight="bold"
            >
              HKP-toalett:
            </Box>{" "}
            Finns
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <Box
              component="span"
              fontWeight="bold"
            >
              Scener och lokal:
            </Box>{" "}
            En utomhusscen finns, och en inomhusarea där också dj:s och distron kommer hålla till.
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <Box
              component="span"
              fontWeight="bold"
            >
              Området:
            </Box>{" "}
            Det mesta av området består av gräs och en ojämn mark som kan vara svårframkomlig. Bra att ha någon med som
            kan hjälpa till att skjuta på om man sitter i rullstol.
          </ListItem>
        </List>
      </Typography>
    </>
  );
};

export default Info;
