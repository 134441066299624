import ResponsiveAppBar from "./components/ResponsiveAppBar";
import { Route, Routes } from "react-router-dom";
import Home from "./views/Home";
import Info from "./views/Info";
import InfoEn from "./views/InfoEn";
import Lineup from "./views/Lineup";
import Volunteer from "./views/Volunteer";
import { createTheme, ThemeProvider } from "@mui/material";
import Tickets from "./views/Tickets";
import Distros from "./views/Distros";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2196F3", // Blue color
    },
    secondary: {
      main: "#FF4081", // Pink color
    },
  },
  typography: {
    fontFamily: "Roboto, sans-serif", // Default font for the entire app
    h5: {
      fontFamily: "Archivo Black, sans-serif", // Custom font for h1
    },
    body2: {
      fontFamily: "Roboto, sans-serif", // Custom font for h2
    },
    // Add more customizations for other typography elements as needed
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      {/*<div>*/}
      {/*  <Typography variant="h1">Custom Font for H1</Typography>*/}
      {/*  <Typography variant="h2">Custom Font for H2</Typography>*/}
      {/*  <Button variant="contained" color="primary">*/}
      {/*    Primary Button (Default Font)*/}
      {/*  </Button>*/}
      {/*  <Button variant="contained" color="secondary">*/}
      {/*    Secondary Button (Default Font)*/}
      {/*  </Button>*/}
      {/*</div>*/}

      <div className="app">
        <ResponsiveAppBar />
        <Routes>
          <Route path="/">
            <Route
              index
              element={<Home />}
            />

            <Route
              path="/info"
              element={<Info />}
            />
            <Route
              path="/info-en"
              element={<InfoEn />}
            />
            <Route
              path="/home"
              element={<Home />}
            />
            <Route
              path="/lineup"
              element={<Lineup />}
            />
            <Route
              path="/volunteer"
              element={<Volunteer />}
            />
            <Route
              path="/distros"
              element={<Distros />}
            />
            <Route
              path="/tickets"
              element={<Tickets />}
            />
          </Route>
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
